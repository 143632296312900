header .pk-top {
  background: white;
  height: 150px; }
  @media screen and (max-width: 767px) {
    header .pk-top {
      height: 100px; } }
  @media screen and (max-width: 575px) {
    header .pk-top {
      height: 30px; } }
  header .pk-top .container-fluid, header .pk-top .row {
    height: 100%; }
  header .pk-top .logo img {
    height: 60px; }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    header .pk-top .info {
      width: 300px; } }
  header .pk-top .info li {
    position: relative;
    padding-left: 48px;
    display: inline-block;
    text-align: left;
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 3px; }
    @media screen and (max-width: 575px) {
      header .pk-top .info li {
        padding-left: 28px; } }
    header .pk-top .info li i {
      background: #383838;
      height: 40px;
      width: 40px;
      border-radius: 100%;
      position: absolute;
      top: -4px;
      left: 0px;
      margin: 3px; }
      header .pk-top .info li i:before {
        font-size: 15px;
        position: absolute;
        color: #faf9fa;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      @media screen and (max-width: 575px) {
        header .pk-top .info li i {
          top: 0px;
          height: 20px;
          width: 20px; }
          header .pk-top .info li i:before {
            font-size: 8px; } }
    header .pk-top .info li p {
      margin-bottom: -9px;
      font-weight: 700; }
      @media screen and (max-width: 575px) {
        header .pk-top .info li p {
          display: none; } }
    header .pk-top .info li a {
      font-size: calc(10px + 3 * ( (100vw - 320px) / 1600)); }
      @media screen and (max-width: 320px) {
        header .pk-top .info li a {
          font-size: 10px; } }
      @media screen and (min-width: 1920px) {
        header .pk-top .info li a {
          font-size: 13px; } }
      @media screen and (max-width: 575px) {
        header .pk-top .info li a {
          font-weight: 700; } }
  header .pk-top .language-list .language-item {
    margin-bottom: 7px; }

header .pk-navbar {
  transition: all 0.15s ease; }
  header .pk-navbar.fixed-top {
    animation: smoothScroll 1s forwards; }
  header .pk-navbar.navbar {
    min-height: 80px;
    background: #383838;
    justify-content: space-between; }
    @media screen and (min-width: 768px) {
      header .pk-navbar.navbar {
        padding: 0 0 0 35px; } }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      header .pk-navbar.navbar {
        padding: 0 0 0 20px; } }
    header .pk-navbar.navbar .nav-item, header .pk-navbar.navbar .nav-wrapper {
      display: flex;
      justify-content: center;
      align-items: center; }
      @media screen and (min-width: 768px) {
        header .pk-navbar.navbar .nav-item, header .pk-navbar.navbar .nav-wrapper {
          height: 80px; } }
      header .pk-navbar.navbar .nav-item .nav-link, header .pk-navbar.navbar .nav-wrapper .nav-link {
        text-transform: uppercase;
        padding: 28px 13px;
        color: white;
        font-weight: 700; }
        @media screen and (min-width: 768px) and (max-width: 1199px) {
          header .pk-navbar.navbar .nav-item .nav-link, header .pk-navbar.navbar .nav-wrapper .nav-link {
            padding: 28px 6px;
            font-size: 13px; } }
      @media screen and (max-width: 767px) {
        header .pk-navbar.navbar .nav-item, header .pk-navbar.navbar .nav-wrapper {
          justify-content: start; }
          header .pk-navbar.navbar .nav-item .nav-link, header .pk-navbar.navbar .nav-wrapper .nav-link {
            width: 100%;
            padding: 0px;
            margin-top: 10px; } }
      header .pk-navbar.navbar .nav-item:hover .nav-link, header .pk-navbar.navbar .nav-item:focus .nav-link, header .pk-navbar.navbar .nav-wrapper:hover .nav-link, header .pk-navbar.navbar .nav-wrapper:focus .nav-link {
        color: #FFCC00; }
      header .pk-navbar.navbar .nav-item.active .nav-link, header .pk-navbar.navbar .nav-wrapper.active .nav-link {
        color: #FFCC00; }
    header .pk-navbar.navbar .nav-wrapper {
      background: #FFCC00; }
      @media screen and (max-width: 767px) {
        header .pk-navbar.navbar .nav-wrapper {
          border-radius: 5px;
          margin: 10px 0; } }
      header .pk-navbar.navbar .nav-wrapper .nav-link {
        padding-left: 50px;
        padding-right: 50px;
        color: #383838; }
        @media screen and (min-width: 768px) and (max-width: 991px) {
          header .pk-navbar.navbar .nav-wrapper .nav-link {
            padding-left: 25px;
            padding-right: 25px; } }
        @media screen and (max-width: 767px) {
          header .pk-navbar.navbar .nav-wrapper .nav-link {
            padding: 5px;
            width: 100%;
            text-align: center;
            margin-top: 0px; } }
      header .pk-navbar.navbar .nav-wrapper:hover .nav-link, header .pk-navbar.navbar .nav-wrapper:focus .nav-link {
        color: #050505; }
  header .pk-navbar .navbar-toggler {
    border: none;
    background: transparent !important;
    outline: 0;
    padding-left: 0;
    padding-right: 15px; }
    header .pk-navbar .navbar-toggler .icon-bar {
      width: 22px;
      height: 2px;
      background-color: #B6B6B6;
      display: block;
      transition: all 0.2s;
      margin-top: 4px; }
    header .pk-navbar .navbar-toggler .top-bar {
      transform: rotate(45deg);
      transform-origin: 10% 10%; }
    header .pk-navbar .navbar-toggler .middle-bar {
      opacity: 0; }
    header .pk-navbar .navbar-toggler .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%; }
    header .pk-navbar .navbar-toggler.collapsed .top-bar {
      transform: rotate(0); }
    header .pk-navbar .navbar-toggler.collapsed .middle-bar {
      opacity: 1; }
    header .pk-navbar .navbar-toggler.collapsed .bottom-bar {
      transform: rotate(0); }
  header .pk-navbar .navbar-brand {
    padding: 5px 0px;
    display: flex; }
    header .pk-navbar .navbar-brand img {
      max-height: 55px; }
  header .pk-navbar .language-list {
    display: flex; }
    header .pk-navbar .language-list .language-item {
      margin-left: 7px; }

header .language-list .selected img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%); }

header .language-list .language-item {
  width: 30px; }
  header .language-list .language-item:hover img {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%); }

header .language-list img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  transition: filter 250ms ease-in-out; }

@keyframes smoothScroll {
  0% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0px); } }

body {
  overflow-x: hidden; }
  body.sticky header {
    background: #454545; }
  body.sticky .pk-navbar {
    box-shadow: 0 2px 14px 5px rgba(20, 20, 20, 0.05); }
  body.sticky section.home, body.sticky .subpage-navigation {
    margin-top: 80px; }
